import React from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";
// eslint-disable-next-line react/prop-types
const RouteChangeTracker = ({ history }) => {
  // eslint-disable-next-line react/prop-types
  history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });

  return <div></div>;
};

export default withRouter(RouteChangeTracker);
