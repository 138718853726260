import React from "react";
import CustomInput from "../CustomInput/CustomInput";
import Card from "../Card/Card";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/componentsSections/basicsStyle";
import Check from "@material-ui/icons/Check";
// eslint-disable-next-line no-unused-vars
import {cardTitle} from "../../assets/jss/material-kit-react";
// eslint-disable-next-line no-unused-vars
import imagesStyles from "../../assets/jss/material-kit-react/imagesStyles";
import {Link} from "react-router-dom";
import Button from "../CustomButtons/Button";

const useStyles = makeStyles(styles);

export function ContactForm() {
    const [checked, setChecked] = React.useState(false);
    const classes = useStyles();

    return (
        <div style={{width: "100%", paddingLeft: "10%", paddingRight: "10%"}}>
            <Card style={{margin: 5}}>
                <h2 style={{textAlign: "center"}} className={classes.title}>
                    Contactformulier
                </h2>

                <form action="mailto:kapellen@hvtegels.be" method="post" encType="text/plain">
                    <div>
                        <div style={{padding: 20}}>
                            <CustomInput
                                name="naam"
                                labelText={<p>Volledige naam</p>}
                                id="float"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                            <CustomInput
                                type="text" name="bericht"
                                labelText="Bericht"
                                id="float"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        tabIndex={-1}
                                        onClick={() => setChecked(!checked)}
                                        checkedIcon={<Check className={classes.checkedIcon}/>}
                                        icon={<Check className={classes.uncheckedIcon}/>}
                                        classes={{
                                            checked: classes.checked,
                                            root: classes.checkRoot,
                                        }}
                                    />
                                }
                                classes={{label: classes.label, root: classes.labelRoot}}
                                label={
                                    <p>
                                        Ik ga akkoord met{" "}
                                        <Link to={"/gebruikersvoorwaarden"}>de voorwaarden</Link> &{" "}
                                        <Link to={"/privacybeleid"}>het privacybeleid</Link>
                                    </p>
                                }
                            />
                        </div>
                        <div
                            style={{
                                width: "100%",
                            }}
                        >
                            <Button
                                type="submit"
                                disabled={!checked}
                                style={{
                                    marginBottom: 20,
                                    alignSelf: "center",
                                    justifySelf: "center",
                                    width: "80%",
                                    marginRight: "10%",
                                    marginLeft: "10%",
                                }}
                            >
                                Verzenden
                            </Button>
                        </div>
                    </div>
                </form>

            </Card>
        </div>
    );
}
