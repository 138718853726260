/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import InstagramIcon from '@material-ui/icons/Instagram';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
        <ListItem className={classes.listItem}>
            <Link className={classes.navLink} style={{textDecoration: "none"}} to={"../tegels/vloer_wandtegels"}>Vloer/Wandtegels</Link>
        </ListItem>

        <ListItem className={classes.listItem}>
            <Link className={classes.navLink} style={{textDecoration: "none"}} to={"../tegels/houtstructuur"}>Houtstructuur Tegels</Link>
        </ListItem>

        <ListItem className={classes.listItem}>
            <Link className={classes.navLink} style={{textDecoration: "none"}} to={"../tegels/terrastegels"}>Terrastegels</Link>
        </ListItem>

        <ListItem className={classes.listItem}>
            <Link className={classes.navLink} style={{textDecoration: "none"}} to={"../contact"}>Contact</Link>
        </ListItem>

      <ListItem className={classes.listItem}>
          <Tooltip
              id="instagram-facebook"
              title="Volg ons op facebook"
              placement={window.innerWidth > 959 ? "top" : "left"}
              classes={{ tooltip: classes.tooltip }}
          >
              <Button
                  color="transparent"
                  href="https://www.facebook.com/HVtegels/"
                  target="_blank"
                  className={classes.navLink}
              >
                  <i className={classes.socialIcons + " fab fa-facebook"} />
              </Button>
          </Tooltip>
          <Tooltip
              id="instagram-facebook"
              title="Volg ons op instagram"
              placement={window.innerWidth > 959 ? "top" : "left"}
              classes={{ tooltip: classes.tooltip }}
          >
              <Button
                  color="transparent"
                  href="https://www.instagram.com/hvtegels/"
                  target="_blank"
                  className={classes.navLink}
              >
                  <i className={classes.socialIcons + " fab fa-instagram"} />
              </Button>
          </Tooltip>
      </ListItem>
    </List>
  );
}
